export const campusMenu = [
    { title: 'Inicio', icon: 'mdi-view-dashboard', route: '/dashboard' },
    { title: 'Cursos', icon: 'mdi-book-open-outline', route: '/cursos#cursos', sesion: ['profesor', 'alumno'] },
    { title: 'Instrumentos', subtitle: 'de evaluación', icon: 'mdi-format-list-checks', route: '/instrumentos', sesion: ['profesor'] },
    { title: 'Exámenes', icon: 'mdi-text-box-check', route: '/examenes', sesion: ['profesor'] },
    { title: 'Encuestas', icon: 'mdi-clipboard-list-outline', route: '/encuestas', sesion: ['profesor'] },
    { title: 'Horarios', icon: 'mdi-table-clock', route: '/horarios', sesion: ['profesor', 'alumno', 'padre'] },
    { title: 'Recursos', icon: 'mdi-file-document-multiple-outline', route: '/recursos', sesion: ['profesor'] },
    { title: 'Videos', icon: 'mdi-video', route: '/videos', sesion: ['profesor'] },
    { title: 'Control parental', icon: 'mdi-account-supervisor-circle', route: '/controlParental', sesion: ['padre'] },
    { title: 'Configuracion', icon: 'mdi-account-cog-outline', route: '/perfil' },

]
export const empresaMenu = [
    { title: 'Inicio', icon: 'mdi-view-dashboard', route: '/dashboard' },
    { title: 'Cursos', icon: 'mdi-book-open-outline', route: '/cursos' },
    { title: 'Instrumentos', subtitle: 'de evaluación', icon: 'mdi-format-list-checks', route: '/instrumentos' },
    { title: 'Exámenes', icon: 'mdi-text-box-check', route: '/examenes' },
    { title: 'Encuestas', icon: 'mdi-clipboard-list-outline', route: '/encuestas' },
    { title: 'Usuarios', icon: 'mdi-account-group', route: '/usuarios' },
    { title: 'Códigos', icon: 'mdi-barcode-scan', route: '/codigos' },
    { title: 'Recursos', icon: 'mdi-file-document-multiple-outline', route: '/recursos' },
    { title: 'Videos', icon: 'mdi-video', route: '/videos' },
    // { title: 'Configuracion', icon: 'mdi-cog', route: '/configuracion' },
]
export const masterMenu = [
    { title: 'Usuarios', icon: 'mdi-account-group', route: '/usuarios' },
    { title: 'Escuelas', icon: 'mdi-school', route: '/escuelas' },
    { title: 'Empresas', icon: 'mdi-office-building', route: '/empresas' },
    { title: 'Avisos', icon: 'mdi-bullhorn-outline', route: '/avisos' },
    { title: 'Permisos', icon: 'mdi-account-group', route: '/permisos' },
]