<template>
  <v-navigation-drawer
    v-model="drawer"
    bottom
    right
    clipped
    app
    dark
    color="menu"
  >
    <v-list nav dense :disabled="blockMenu">
      <v-divider></v-divider>
      <template v-for="(item, index) in localApps">
        <v-list-item :key="item.title" @click="generarToken(item)">
          <v-list-item-icon>
            <v-icon v-if="item.tipo == 'campus'">mdi-laptop</v-icon>
            <v-icon v-if="item.tipo == 'empresa'">mdi-office-building</v-icon>
            <v-icon v-if="item.tipo == 'escuela'">mdi-school</v-icon>
            <v-icon v-if="item.tipo == 'master'"
              >mdi-alpha-m-circle-outline</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.nombreCorto }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-progress-linear
                :active="item.loading"
                :indeterminate="item.loading"
                absolute
                bottom
                color="white"
              ></v-progress-linear>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { getRedirectionToken, cerrarSesionService } from "./main.service";

export default {
  name: "appsMenu",
  props: {
    drawer: Boolean,
  },
  computed: {
    ...mapGetters([
      "apps",
      "redirect",
      "redirectEmpresa",
      "redirectEscuela",
      "redirectMaster",
    ]),
  },
  watch:{
    apps(){
      this.createLocalApps()
    }
  },
  data: () => ({
    menu: false,
    blockMenu: false,
    localApps: []
  }),
  mounted(){
    this.createLocalApps()
  },
  methods: {
    createLocalApps(){
      this.localApps = this.apps.map(app => ({...app, loading: false}))
    },
    async generarToken(app) {
      try {
        this.blockMenu = true;
        app.loading = true
        const serverResponse = await getRedirectionToken(app.tipo, app._id);
        if (!serverResponse.ok) {
          this.blockMenu = false;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else this.cambiarApp(serverResponse.tokenRedireccion, app.tipo);
      } catch (error) {
        this.blockMenu = false;
        this.$appErrorMessage();
      }
    },
    async cambiarApp(tokenRedireccion, app) {
      try {
        const serverResponse = await cerrarSesionService();
        if (!serverResponse.ok) {
          this.blockMenu = false;
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$store.dispatch("setSessionToken", null);
          localStorage.removeItem("agnus-token");

          let redirect = "";
          if (app == "campus") redirect = this.redirect;
          if (app == "empresa") redirect = this.redirectEmpresa;
          if (app == "escuela") redirect = this.redirectEscuela;
          if (app == "master") redirect = this.redirectMaster;

          window.location.href = `${redirect}/cambioApp/${tokenRedireccion}`;
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  },
};
</script>
