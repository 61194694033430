<template>
  <v-navigation-drawer
    v-model="drawer"
    permanent
    :mini-variant.sync="mini"
    expand-on-hover
    width="220"
    color="menu"
    dark
    app
    clipped
  >
    <v-list nav>
      <template v-for="(item, index) in menuFiltrado">
        <v-list-item
          v-if="!item.group"
          :key="`menuItem-${index}`"
          link
          @click="redirectMenu(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">{{
              item.subtitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          :key="`group-${index}`"
          :value="false"
          :prepend-icon="item.icon"
          no-action
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(subitem, index) in item.items"
            :key="`subitem-${index}`"
            style="padding-left: 55px"
            @click="redirectMenu(subitem.route)"
          >
            <v-list-item-content>
              <v-list-item-title>{{subitem.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>

      <v-list-item link @click="cerrrarSesion()">
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import {
  campusMenu,
  empresaMenu
} from "./mainMenu.service";
import { cerrarSesionService } from "./main.service";

export default {
  name: "mainMenu",
  props: ["app"],
  computed: {
    ...mapGetters(["userAvatar", "tipoSesion", "redirect", "menu", "apps"]),
    menuFiltrado() {
      if (this.app !== "campus") return this.localMenu;
      const menuLocal = [];
      const escuelas = this.apps.filter( app => app.tipo === 'escuela');
      this.localMenu.forEach((menuItem) => {
        if (!menuItem.sesion) menuLocal.push(menuItem);
        if (menuItem.title === 'Horarios' && escuelas.length > 0){
          menuLocal.push(menuItem);
        } else {
          const diff = _.intersection(this.tipoSesion, menuItem.sesion);
          if (diff.length > 0) menuLocal.push(menuItem);
        }
      });
      return menuLocal;
    },
  },
  data() {
    return {
      drawer: true,
      mini: true,
      localMenu: [],
    };
  },
  watch: {
    'menu':function(val){
      if(val.length) this.localMenu = val;
    }
  },
  mounted(){
    if (this.app == "campus") this.localMenu = campusMenu;
    if (this.app == "empresa") this.localMenu = empresaMenu;
    if (this.app == "escuela") this.localMenu = this.menu;
    if (this.app == "master") this.localMenu = this.menu;
    this.$store.dispatch("setMenu",this.localMenu);
  },
  created(){
    if (this.app == "campus") this.localMenu = campusMenu;
    if (this.app == "empresa") this.localMenu = empresaMenu;
    if (this.app == "escuela") this.localMenu = this.menu;
    if (this.app == "master") this.localMenu = this.menu;
    this.$store.dispatch("setMenu",this.localMenu);
  },
  methods: {
    redirectMenu(route) {
      if (this.$route.path != route) this.$router.push(route);
    },
    async cerrrarSesion() {
      try {
        // this.loadingLocal = true;
        const serverResponse = await cerrarSesionService();
        // this.loadingLocal = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$store.dispatch("setSessionToken", null);
          localStorage.removeItem("agnus-token");
          this.$store.dispatch("setTipoSesion", []);
        }
        if (this.app == "campus") this.$router.push("/login");
        else window.location.href = `${this.redirect}`;
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  },
};
</script>
