import { postServerRequest, deleteServerRequest } from '../../../globals/services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function cargarSesion(sessionToken) {
    const url = `${httpURL}/cargarSesion`;
    const options = { headers: { token: sessionToken } }
    return await postServerRequest(url, {}, options);
}

export async function cerrarSesionService() {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/cerrarSesion`;
    const data = { headers: { token: sessionToken } }
    return await deleteServerRequest(url, data);
}

export async function getRedirectionToken(app, idApp) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/cambioApp/token`;
    const data = { app, idApp };
    const options = { headers: { token: sessionToken } }
    return await postServerRequest(url, data, options);
}