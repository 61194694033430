<template>
  <v-app-bar color="primary" dark flat app clipped-left clipped-right>
    <v-avatar size="35" style="margin-right: 10px">
      <v-icon size="30" v-if="!userAvatar">mdi-account</v-icon>
      <img v-else :src="`${imageURL}/icon/${userAvatar}`" alt="User" />
    </v-avatar>

    <v-spacer></v-spacer>
    <v-toolbar-title>
      <h3>{{ appName }}</h3>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn v-if="apps.length > 0" icon @click.stop="showDrawer()"
      ><v-icon>mdi-menu</v-icon></v-btn
    >

    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="white"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "appBar",
  props: { loading: Boolean, app: String, apps: Array },
  computed: {
    ...mapGetters(["userAvatar", "imageURL", "appName"]),
  },
  data() {
    return {
      loadingLocal: false,
      drawer: false,
    };
  },
  methods: {
    showDrawer() {
      this.drawer = !this.drawer;
      this.$emit("showDrawer", this.drawer);
    },
  },
};
</script>
