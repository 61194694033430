<template>
  <v-app style="background-color: #b0bec5">
    <appBarComponent
      :app="app"
      :apps="apps"
      :loading="loading"
      @showDrawer="showMenuApps"
    />
    <mainMenuComponent :app="app" />
    <appsMennuComponent v-if="apps.length > 0" :drawer="rightDrawer" />
    <v-main style="padding: 56px 0px 0px 56px">
      <router-view></router-view>
    </v-main>
    <footerComponent />
    <systemMessageComponent />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import appBarComponent from "./appBar.vue";
import mainMenuComponent from "./mainMenu.vue";
import footerComponent from "./footer.vue";
import systemMessageComponent from "../systemMessage.vue";
import appsMennuComponent from "./appsMenu.vue";

import { cargarSesion } from "./main.service";
export default {
  name: "mainView",
  components: {
    appBarComponent,
    mainMenuComponent,
    appsMennuComponent,
    footerComponent,
    systemMessageComponent,
  },
  computed: {
    ...mapGetters([, "apps", "app", "redirect"]),
  },
  data() {
    return {
      loading: false,
      activaError: false,
      rightDrawer: false,
    };
  },
  async created() {
    const sessionToken = localStorage.getItem("agnus-token");
    const sessionPadreToken = localStorage.getItem("agnus-padre-token");
    if (!sessionToken) this.$router.push("/login");

    try {
      this.loading = true;
      const serverResponse = await cargarSesion(sessionToken);
      this.loading = false;
      if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
      else {
        const usuario = serverResponse.usuario;
        this.$store.dispatch("setSessionToken", sessionToken);
        this.$store.dispatch("setUserName", usuario.nombreCompleto);
        this.$store.dispatch("setUserAvatar", usuario.avatar);
        this.$store.dispatch("setApps", serverResponse.usuarioApps);
        this.$store.dispatch("setUserId", usuario._id);
        this.$store.dispatch("setTimezone", usuario.timezone);
        const configuraciones = serverResponse.configuraciones;
        if(sessionPadreToken) this.$store.dispatch("setSesionCursoPadre", true);
        if (this.app == "master") {
          this.$store.dispatch("setMenu", usuario.master.menu);
        }
        if (this.app == "campus"){
          this.$store.dispatch("setTipoSesion", usuario.tipoSesion);
          this.$store.dispatch("setTokenSesionHijo", sessionPadreToken);
          this.$store.dispatch("setHorarioPersonalizado", configuraciones.horarioPersonalizado);
          this.$store.dispatch("setHorasPersonalizadas", configuraciones.horasPersonalizadas);
        }
        if (this.app == "empresa") {
          this.$store.dispatch("setAppName", usuario.empresa);
          this.$store.dispatch("setRole", usuario.role);
        }
        if (this.app == "escuela") {
          this.$store.dispatch("setAppName", usuario.escuela.nombre);
          this.$store.dispatch("setRole", usuario.role);
          this.$store.dispatch("setMenu", usuario.escuela.menu);
          this.$store.dispatch("setPermisos", serverResponse.permisos);
          //configuraciones
          this.$store.dispatch("setCodigoAlumnos", configuraciones.codigoAlumnos);
          this.$store.dispatch("setCodigoProfesores", configuraciones.codigoProfesores);
          this.$store.dispatch("setCodigoAdministrativos", configuraciones.codigoAdministrativos);
          this.$store.dispatch("setCodigoCarreras", configuraciones.codigoCarreras);
          this.$store.dispatch("setCodigoDeptos", configuraciones.codigoDeptos);
          this.$store.dispatch("setCodigoMateria", configuraciones.codigoMateria);
          this.$store.dispatch("setCodigoSedes", configuraciones.codigoSedes);
          this.$store.dispatch("setCodigoAulas", configuraciones.codigoAulas);
          this.$store.dispatch("setClaveClases", configuraciones.claveClases);
          this.$store.dispatch("setGrupoClases", configuraciones.grupoClases);
          this.$store.dispatch("setPrimario", configuraciones.primario);
          this.$store.dispatch("setSecundario", configuraciones.secundario);
          this.$store.dispatch("setHorarioMostrarClaveCuadricula", configuraciones.horariosCuadricula.mostrarClave || false);
          this.$store.dispatch("setHorarioMostrarAulaCuadricula", configuraciones.horariosCuadricula.mostrarAula || false);
          this.$store.dispatch("setHorarioPersonalizado", configuraciones.horarioPersonalizado);
          this.$store.dispatch("setHorasPersonalizadas", configuraciones.horasPersonalizadas);
          this.$store.dispatch("setMostrarNombreCurso", configuraciones.mostrarNombreCurso);
          this.$store.dispatch("setMostrarAlumnos", configuraciones.mostrarAlumnos);
          this.$store.dispatch("setMostrarDepto", configuraciones.mostrarDepto);
          this.$store.dispatch("setMostrarAula", configuraciones.mostrarAula);
        }
      }
    } catch (error) {
      this.loading = false;
      if (error.operational) this.$systemErrorMessage(error.message);
      else this.$appErrorMessage();
      localStorage.removeItem("agnus-token");
      localStorage.removeItem("agnus-padre-token");
      if (this.app == "campus") this.$router.push("/login");
      else window.location.href = `${this.redirect}`;
    }
  },
  methods: {
    showMenuApps(show) {
      this.rightDrawer = show;
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
.link {
  text-decoration: none;
}
</style>
